
import { useEffect, useState } from 'preact/hooks';
import { Button, Collapse, Typography } from '@material-ui/core';
import { useSnackbar } from "notistack";
import { useTheme } from '@material-ui/core/styles';
import { Workbox, messageSW } from 'workbox-window';
import { Alert, AlertTitle } from '@material-ui/lab';
const wb = new Workbox('/service-worker.js');

let registration;

const SwWatcher = () => {
	const [newVersionAvailable, setNewVersionAvailable] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const theme = useTheme();
	const [alertOpen, setAlertOpen] = useState(false);
	const updateAction = () => {
		return (
			<Fragment>
				<Button
					className="snackbar-button"
					size="small"
					onClick={updateServiceWorker}
				>{"Update"}
				</Button>
			</Fragment>
		)
	}

	const updateServiceWorker = (event, data) => {
		// Assuming the user accepted the update, set up a listener
		// that will reload the page as soon as the previously waiting
		// service worker has taken control.
		wb.addEventListener('controlling', (event) => {
			window.location.reload();
		});

		if (registration && registration.waiting) {
			// Send a message to the waiting service worker,
			// instructing it to activate.  
			// Note: for this to work, you have to add a message
			// listener in your service worker. See below.
			messageSW(registration.waiting, { type: 'SKIP_WAITING' });
			setAlertOpen(false);
		}
	}


	useEffect(() => {
		if (process.env.NODE_ENV === 'production') {
			if ('serviceWorker' in navigator) {
				const showSkipWaitingPrompt = (event) => {
					setAlertOpen(true);
					// `event.wasWaitingBeforeRegister` will be false if this is
					// the first time the updated service worker is waiting.
					// When `event.wasWaitingBeforeRegister` is true, a previously
					// updated service worker is still waiting.
					// You may want to customize the UI prompt accordingly.

					// Assumes your app has some sort of prompt UI element
					// that a user can either accept or reject.
				};

				// Add an event listener to detect when the registered
				// service worker has installed but is waiting to activate.
				wb.addEventListener('waiting', showSkipWaitingPrompt);
				wb.addEventListener('externalwaiting', showSkipWaitingPrompt);
				wb.register().then((r) => {
					registration = r;
				});
			}
		}
	}, []);

	return (
		<Collapse in={alertOpen} style={{
			zIndex: 100000, position: 'absolute',
			left: 0,
			right: 0,
			bottom: 0,
		}}>
			<Alert variant="filled" severity="info" style={{
				borderRadius: 0, display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: theme.palette.secondary.main,
				height: '100px'
			}}
			><div style={{
				borderRadius: 0, display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'center'
			}}>
					<Typography variant="body2">Es ist eine neue Version verfügbar</Typography>
					<Button style={{
						marginLeft: '20px',
						paddingLeft: '8px',
						paddingRight: '8px',
						borderRadius: "18px",
						color: theme.palette.gray.dark
					}}
						variant="contained"
						onClick={updateServiceWorker}
					>Aktualisieren
				</Button>
				</div>
			</Alert>
		</Collapse >
	)
};

export default SwWatcher;
