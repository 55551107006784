import { createState, useState } from '@hookstate/core';
import { LOGIN_STATUS } from '/lib/constants';
import { configurationService } from '/services';
import { Persistence } from '@hookstate/persistence';


const clientConfigurationState = createState({
    waiverConfirmed: false,
    tenantDetails: {
        logo: '',
        pageTitle: 'Trainerio Übungen',
        homepageLink: 'https://www.trainerio.com',
        homepageDeepLink: 'https://www.trainerio.com',
    },
    themeDetails: {
        light: {
            overrides: {
                MuiCssBaseline: {
                    '@global': {
                        html: {
                            height: '100%',
                        },
                        body: {
                            height: '100%',
                        },
                    },
                },
                MuiLink: {
                    root: {
                        cursor: 'pointer',
                        color: '#D6C837'
                    },
                },
                MUIDataTable: {
                    responsiveScroll: {
                        overflowY: 'scroll',
                        maxHeight: 'none',
                    },
                },
                MuiTimelineItem: {
                    missingOppositeContent: {
                        "&:before": {
                            display: "none"
                        }
                    }
                },
                MuiListItem: {
                    root: {
                        paddingTop: 0,
                        paddingBottom: 0,

                    },
                    gutters: {
                        paddingLeft: 8,
                    },
                },
                MuiListItemText: {
                    primary: {
                        fontSize: '0.9rem',
                    },
                },
                MuiListItemAvatar: {
                    root: {
                        minWidth: 50,
                    },
                },

            },
            palette: {
                headerGradient: {
                    primary: 'linear-gradient(315deg, #8BDDCF 0%, #2EA28F 24%, #3CC7B1 100%)',
                    secondary: 'linear-gradient(315deg, #955FDF 0%, #6425B9 24%, #955FDF 100%)',
                },

                gradient: {
                    primary: 'linear-gradient(to right bottom, #8BDDCF, #2EA28F)',
                    secondary: 'linear-gradient(to right bottom, #4900E3, #9B51E0)',
                    tertiary: 'linear-gradient(to right bottom, #DEDCBD, #E0D56A)',
                    gray: 'linear-gradient(to right bottom, #C7C8D9, #8F90A6)',
                },
                buttonGradient: {
                    primary: 'linear-gradient(to right bottom, #4900E3, #9B51E0)',
                    secondary: 'linear-gradient(to right bottom, #4900E3, #9B51E0)',
                    gray: 'linear-gradient(to right bottom, #C7C8D9, #8F90A6)',
                },
                background: {
                    default: '#FAFAFC',
                },
                primary: {
                    light: '#8BDDCF',
                    main: '#3CC7B1',
                    dark: '#2EA28F',
                },
                secondary: {
                    light: '#BE9DEB',
                    main: '#955FDF',
                    dark: '#6425B9',
                },
                tertiary: {
                    light: '#DEDCBD',
                    main: '#E0D56A',
                    dark: '#D6C837',
                },
                gray: {
                    light: '#EBEBF0',
                    main: '#C7C8D9',
                    dark: '#8F90A6',
                },
                type: 'light',
            },
            typography: {
                h1: {
                    fontFamily: [
                        'Inter',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                    ].join(','),
                },
                h2: {
                    fontFamily: [
                        'Inter',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                    ].join(','),
                },
                h4: {
                    fontFamily: [
                        'Inter',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                    ].join(','),
                },
                h5: {
                    fontFamily: [
                        'Inter',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                    ].join(','),
                },
                h6: {
                    fontFamily: [
                        'Inter',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                    ].join(','),
                },
                fontFamily: 'Inter , Roboto, "Helvetica Neue" , Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                subtitle2: {
                    color: '#3CC7B1',
                    fontSize: '1rem',
                },
                useNextVariants: true,
            },

        }
    }
})

export function useClientConfigurationState() {
    const state = useState(clientConfigurationState);
    if (typeof window !== 'undefined') {
        state.attach(Persistence('trainerio-client-config'));
    }

    return ({
        getClientConfiguration() {
            return configurationService.getClientConfiguration()
                .then(async (clientConfiguration) => {
                    state.set(oldState =>
                        ({
                            ...oldState,
                            ...clientConfiguration,
                        }));
                })
                .catch((error) => {
                    return "error";
                });
        },
        setWaiverConfirmed() {
            state.set(oldState =>
            ({
                ...oldState,
                waiverConfirmed: true
            }));
        },
        get themeDetails() {
            return JSON.parse(JSON.stringify(state.themeDetails.get()));
        },
        get tenantDetails() {
            return JSON.parse(JSON.stringify(state.tenantDetails.get()));
        },
        get hasWaiverConfirmed() {
            return String(state.waiverConfirmed.get()) === "true";
        },
    })
}