import axios from 'axios';
//import { store } from '/lib/helpers';
//import { asyncRequestActions } from 'actions';
import {
    csrfHeader,
} from '/lib/helpers/header';

const HEADER_TYPES = {
    PUBLIC: 'public',
    AUTHORIZED: 'authorized',
    UPLOAD: 'upload',
};

const getHeaders = (headersType = HEADER_TYPES.PUBLIC) => {
    switch (headersType) {
        case HEADER_TYPES.AUTHORIZED:
            return {
                ...{ 'Content-Type': 'application/json' }, ...csrfHeader(),
            };
        case HEADER_TYPES.UPLOAD:
            return {
                ...{ 'Content-Type': 'multipart/form-data' }, ...csrfHeader(),
            };
        case HEADER_TYPES.PUBLIC:
        default:
            return {
                ...{ 'Content-Type': 'application/json' },
            };
    }
};

const defaultOptions = (url, headersType = HEADER_TYPES.PUBLIC) => ({
    headers: getHeaders(headersType),
    baseURL: (url.startsWith('public') || url.startsWith('media'))  ? '/' : "/api",
    withCredentials: true,
    responseType: url.startsWith('media') ? 'blob' : 'json'
});

const request = (method, url, options, headersType = HEADER_TYPES.PUBLIC) => {
    const parsedOptions = options;
    if (headersType === HEADER_TYPES.UPLOAD) {
        if (options.data.hasOwnProperty('requestId')) {
            const { requestId } = options.data;
            parsedOptions.onUploadProgress = (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                //store.dispatch(asyncRequestActions.updateProgress(requestId, percentCompleted));
            };
        }
        const formData = new FormData();

        Object.entries(parsedOptions.data).map(([key, value]) => {
            if (value instanceof File) {
                formData.append(key, value);
                delete parsedOptions.data[key];
            } else {
                formData.set(key, value);
            }
            return value;
        });

        parsedOptions.data = formData;
    }

    const requestOptions = {
        ...defaultOptions(String(url), headersType), ...parsedOptions, method, url,
    };

    const onSuccess = (response) => (requestOptions.raw ? response : response.data);

    const onError = (error) => {
        let errorResponse;

        if (error.response) {
            errorResponse = error.response.data;
        } else {
            errorResponse = {
                status: 'error',
                statusCode: 499,
                message: 'Internal Client Error',
                errors: error.message,
            };
        }
        if (errorResponse.statusCode === 401) {
            logout(true);
        }
        return Promise.reject(errorResponse);
    };
    return axios(requestOptions)
        .then(onSuccess)
        .catch(onError);
};

const logout = (clientOnly = false) => {
    // localStorage.clear();
    if (!clientOnly) {
        request('GET', '/auth/logout', HEADER_TYPES.AUTHORIZED).then(() => { });
    }
    // history.push('/login');
    return {};
};

export const commonService = {
    HEADER_TYPES,
    request,
};
