import { createState, useState } from '@hookstate/core';
import { LOGIN_STATUS } from '/lib/constants';
import { userService } from '/services';
import { useAlertState } from '/lib/states/alert';
import { Persistence } from '@hookstate/persistence';

const userState = createState({
    status: LOGIN_STATUS.LOGGED_OUT,
    errorCount: 0,
    qrCode: '',
    user: {},
    socket: null,
})

export function useUserState() {
    const state = useState(userState);
    if (typeof window !== 'undefined') {
        state.attach(Persistence('trainerio-user'));
    }
    const alertState = useAlertState();

    return ({
        login(username, password, rememberMe) {
            return userService.login(username, password, rememberMe)
                .then((user) => {
                    alertState.removeAlert('login');
                    userService.getPreferences().then(async (userPreferences) => {
                        state.set(oldState =>
                        ({
                            ...oldState,
                            status: LOGIN_STATUS.LOGGED_IN,
                            errorCount: 0,
                            qrCode: '',
                            user: userPreferences,
                        }));
                    });
                })
                .catch((error) => {
                    alertState.addError(error, 'login');
                    return "error";
                });
        },
        verifySession() {
            return userService.verifySession()
                .then((verificationResult) => {
                    if (verificationResult.logged_in) {
                        userService.getPreferences().then(async (userPreferences) => {
                            state.set(oldState =>
                            ({
                                ...oldState,
                                status: LOGIN_STATUS.LOGGED_IN,
                                errorCount: 0,
                                qrCode: '',
                                user: userPreferences,
                            }));
                        });
                    } else {
                        state.set(oldState => ({
                            ...oldState,
                            status: LOGIN_STATUS.LOGGED_OUT,
                            errorCount: 0,
                            qrCode: '',
                            user: userPreferences,
                        }));
                    }
                })
                .catch((error) => {
                    return "error";
                });
        },
        updatePreferences(data) {
            return userService.updatePreferences(state.user.get().id, data)
                .then(() => {
                    userService.getPreferences().then(async (userPreferences) => {
                        state.set(oldState =>
                        ({
                            ...oldState,
                            status: LOGIN_STATUS.LOGGED_IN,
                            errorCount: 0,
                            qrCode: '',
                            user: userPreferences,
                        }));
                    });

                })
                .catch((error) => {
                    return "error";
                });
        },
        abortLogin() {
            state.set(oldState =>
            ({
                ...oldState,
                status: LOGIN_STATUS.LOGGED_OUT,
                errorCount: 0,
                user: {},
                qrCode: '',
            }));
        },
        setTheme(newTheme) {
            state.set(oldState =>
            ({
                ...oldState,
                theme: newTheme,
            }));
        },
        logout() {
            userService.logout();
            state.set(oldState =>
            ({
                ...oldState,
                status: LOGIN_STATUS.LOGGED_OUT,
                errorCount: 0,
                user: {},
                qrCode: '',
            }));
        },
        get getLoginStatus() {
            return state.status.get();
        },
        get isLoggedIn() {
            return String(state.status.get()) === LOGIN_STATUS.LOGGED_IN;
        },
        get user() {
            return JSON.parse(JSON.stringify(state.user.get()));
        },
        get qrCode() {
            return state.qrCode.get();
        },
        get userTheme() {
            const user = JSON.parse(JSON.stringify(state.user.get()))
            return user.hasOwnProperty("UserProfile") ? user.UserProfile.theme : 'light';
        }
    })
}