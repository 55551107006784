import { useEffect, useState } from 'preact/hooks';
import { useDownloadQueue } from '/lib/states/downloadQueue';
import { exerciseService, db } from '/services';

import { Paper, Typography } from '@material-ui/core';

const DownloadQueue = (props) => {

    const { getDownloadQueue, getAllDownloadQueue, updateDownloadQueue, deleteFromDownloadQueue } = useDownloadQueue();
    const [isDownloading, setIsDownloading] = useState(false);

    const [currentWorkouts, setCurrentWorkouts] = useState([]);

    useEffect(() => {
        console.log(getAllDownloadQueue);
        if (getAllDownloadQueue !== currentWorkouts) {
            setCurrentWorkouts([...getAllDownloadQueue]);
        }

    }, [getAllDownloadQueue]);

    useEffect(() => {
        console.log(currentWorkouts);
        processDownloadQueue();
    }, [currentWorkouts]);


    const processDownloadQueue = async (source) => {
        let isDone = true;
        if (currentWorkouts.length) {
            const tempWorkout = currentWorkouts[0];
            if (tempWorkout.files.length) {
                isDone = false;
                const file = tempWorkout.files.shift();
                if (!isDownloading) {

                    setIsDownloading(true);
                    await exerciseService.getExerciseMedia(file.id, file.baselinePath ? file.baselinePath.substring(1) : file.path.substring(1)).then(async (media) => {
                        await db.media.put({
                            workoutId: tempWorkout.id,
                            path: file.path,
                            file: media
                        }, "[workoutId+path]");
                        setIsDownloading(false);
                        updateDownloadQueue(tempWorkout);
                    }).catch((err) => {

                        setIsDownloading(false);
                        updateDownloadQueue(tempWorkout);
                    });
                }
            }
            if (isDone) {
                await db.workout.put({
                    workoutId: tempWorkout.id,
                    name: tempWorkout.name,
                    isOfflineAvailable: true,
                    downloadDate: new Date(),
                });
                deleteFromDownloadQueue(tempWorkout.id);
            }
        }
    }


    return (
        <div style={{
            backgroundColor: '#fffff', position: 'fixed', bottom: 0, margin: 'auto', zIndex: 20000,
            width: '100%',
            maxWidth: 640
        }}>
            {getDownloadQueue().map(workout => <div>
                <Paper elevation={6} square style={{ padding: 24 }}>
                    <Typography variant="body1">Herunterladen von <b>{workout.name}</b><br /></Typography>
                    <Typography variant="body2">
                        {workout.totalFiles - workout.files.length} von {workout.totalFiles} Dateien gespeichert
                    </Typography>
                </Paper>
            </div>)}
        </div>
    );
}
export default DownloadQueue;
