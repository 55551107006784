import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import GetAppIcon from '@material-ui/icons/GetApp';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { Fab } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.secondary.main,
        margin: theme.spacing(1)
    },
});

const getIcon = (variant) => {
    switch (variant) {
        case "back":
            return <ArrowBackIcon />;
        case "close":
            return <CloseIcon />;
        case "user":
            return <PersonOutlineIcon />;
        case "delete":
            return <DeleteIcon />;

        case "up":
            return <ArrowUpIcon />;
        case "next":
            return <ArrowForwardIcon />;
        case "restart":
            return <SettingsBackupRestoreIcon />;
        case "new":
            return <AddIcon />;
        case "download":
            return <GetAppIcon />;
        case "download-file":
            return <DescriptionIcon />;
        case "alert-add":
            return <NotificationsNoneIcon />;
        case "alert":
            return <NotificationsIcon />;
        case "alert-active":
            return <NotificationsActiveIcon />;
        case "home":
        default:
            return <HomeOutlinedIcon />;
    }
}
export const HeaderIcon = withStyles(styles)((props) => {
    const { disabled = false, variant, position, children, classes, onClick, style, ...other } = props;

    return (

        <Fab disabled={disabled} size="small" className={classes.root} onClick={onClick ? onClick : null} style={style}>
            {getIcon(variant)}
        </Fab>
    )

});
