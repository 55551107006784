const pushServerPublicKey = 'BIN2Jc5Vmkmy-S3AUrcMlpKxJpLeVRAfu9WBqUbJ70SJOCWGCGXKY-Xzyh7HDr6KbRDGYHjqZ06OcS3BjD7uAm8';

/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
        if (window.Notification.permission === 'denied'){
            return false;
        }
        return true;
    } else {
        return false;
    }
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
async function askUserPermission() {
    return await window.Notification.requestPermission();
}
/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
    // wait for service worker installation to be ready
    const serviceWorker = await navigator.serviceWorker.ready;
    // subscribe and return the subscription
    return await serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: pushServerPublicKey,
    });
}

/**
 * returns the subscription if present or nothing
 */
function getUserSubscription() {
    // wait for service worker installation to be ready, and then
    return navigator.serviceWorker.ready
        .then((serviceWorker) => serviceWorker.pushManager.getSubscription())
        .then((pushSubscription) => pushSubscription);
}

export {
    isPushNotificationSupported,
    askUserPermission,
    createNotificationSubscription,
    getUserSubscription,
};
