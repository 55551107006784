import { useEffect, useState, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {
    Typography, Avatar, CardContent, Card, Paper, LinearProgress, Slide, Slider, withStyles, Button as MuiButton, Radio, FormControlLabel, RadioGroup, Checkbox, TextField, InputAdornment, Grid
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

import { useClientConfigurationState } from '/lib/states/clientConfiguration';
import usePushNotifications from '/components/common/pushNotification/usePushNotifications';
import PushNotificationConfiguration from '/components/common/pushNotification/pushNotificationConfiguration';
import { HeaderIconGroup, HeaderIcon, Button } from '/components/common';
import { useMediaQuery } from '@material-ui/core';

import { Offline, Online } from "react-detect-offline";
import { pushNotificationService } from '/services/pushNotification';


function AirbnbThumbComponent(props) {
    return (
        <span {...props}>
            <span className="bar" />
            <span className="bar" />
            <span className="bar" />
        </span>
    );
}



const AirbnbSlider = withStyles((theme) => ({
    root: {
        color: theme.palette.gray.light,
        height: 3,
        padding: '13px 0',
    },
    thumb: {
        height: 27,
        width: 27,
        backgroundColor: theme.palette.primary.main,
        border: '1px solid currentColor',
        marginTop: -12,
        marginLeft: -13,
        boxShadow: '#FFFFFF 0 2px 2px',
        '&:focus, &:hover, &$active': {
            boxShadow: '#ccc 0 2px 3px 1px',
        },
        '& .bar': {
            // display: inline-block !important;
            height: 9,
            width: 1,
            backgroundColor: '#FFFFFF',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    mark: {
        backgroundColor: theme.palette.gray.dark,
        height: 8,
        width: 8,
        marginTop: -2,
        borderRadius: '50px'
    },
    active: {},
    track: {
        height: 4,
    },
    rail: {
        color: theme.palette.gray.light,
        opacity: 1,
        height: 4,
    },
}))(Slider);

export const CompletionDialog = (props) => {
    const classes = useStyles();
    const { onClose, open, workoutId, ...other } = props;
    const [value, setValue] = useState(2);
    const radioGroupRef = useRef(null);

    const {
        pushNotificationSupported,
        userSubscription
    } = usePushNotifications();

    const [trainingDays, setTrainingDays] = useState({
    });
    const [trainingTime, setTrainingTime] = useState(new Date());

    const { tenantDetails } = useClientConfigurationState();
    const theme = useTheme();
    const matchesDesktop = useMediaQuery(theme.breakpoints.up('sm'));



    const init = async () => {
        if (userSubscription) {
            const notificationSchedule = await pushNotificationService.getNotificationSchedule(userSubscription, workoutId).then((result) => {
                return result;
            }).catch((err) => {
                return [];
            });
            if (notificationSchedule.id) {
                setTrainingDays({
                    monday: notificationSchedule.send_mondays,
                    tuesday: notificationSchedule.send_tuesdays,
                    wednesday: notificationSchedule.send_wednesdays,
                    thursday: notificationSchedule.send_thursdays,
                    friday: notificationSchedule.send_fridays,
                    saturday: notificationSchedule.send_saturdays,
                    sunday: notificationSchedule.send_sundays,
                });
                setTrainingTime(notificationSchedule.sending_time);
            }
        }
    };

    useEffect(() => {
        init();
    }, [open]);

    const handleOk = () => {
        onClose();
    };


    const rates = [
        {
            "label": "schlecht",
            "value": 0
        },
        {
            "label": "",
            "value": 1
        },
        {
            "label": "",
            "value": 2
        },
        {
            "label": "",
            "value": 3
        },
        {
            "label": "sehr gut",
            "value": 4
        },
    ]
    function getWeekday(number) {
        const weekdayNames = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
        return weekdayNames[number];
    }


    return (
        <Dialog
            fullScreen={matchesDesktop ? false : true}
            fullWidth={true}
            maxWidth={"sm"}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">Übungen Abgeschlossen</DialogTitle>
            <DialogContent dividers>
                <div style={{ textAlign: 'center' }}>
                    <img src="/assets/images/clap.gif" style={{ maxWidth: '100%', width: '100%' }} />
                </div>
                <br />
                <Paper square style={{ padding: theme.spacing(1) }} >
                    <Typography variant="h3" color="secondary">Wie geht es Dir nach Ausüben der Übungen?</Typography>
                    <div style={{ margin: theme.spacing(3), position: 'relative' }}>
                        <AirbnbSlider
                            onChange={(e, value) => setValue(value)}
                            style={{ marginTop: 10 }}
                            marks={rates}
                            value={value}
                            ThumbComponent={AirbnbThumbComponent}
                            className={classes.sliderRoot}
                            min={0}
                            getAriaValueText={() => ""}
                            max={rates.length - 1}
                            step={1}
                        />
                    </div>
                    {value < 2 &&
                        <Card>
                            <CardContent>
                                <Typography variant="body2" color="textSecondary" component="span">
                                    Möchtest du vielleicht einen Nachsorgetermin mit {tenantDetails.name} vereibaren?
                                    <br />
                                    <br />
                                    <Online>
                                        <a href={tenantDetails.homepageDeepLink} > zur Webseite von {tenantDetails.name}</a>
                                    </Online>
                                </Typography>
                            </CardContent>
                        </Card>
                    }
                </Paper>
                <br />


                {pushNotificationSupported &&
                    <Paper square style={{ padding: theme.spacing(1) }} >
                        <Typography variant="h3" color="secondary">Erinnerungen</Typography>
                        <br />
                        <Typography variant="body2">Für einen optimalen Erfolg wäre es gut, die Übungen regelmäßig durchzuführen. Lass dich ans Training erinnern</Typography>
                        <br />
                        <Offline>Die Konfiguration der Erinnerungen funktioniert nur wenn du Online bist.</Offline>
                        <Online>
                            {trainingDays.hasOwnProperty("monday") &&
                                (<>
                                    {'Trainingstage:' + (Object.values(trainingDays).filter((v) => v).length > 0 ? " " + Object.values(trainingDays).map((value, index) => value ? getWeekday(index) : "").filter((v) => v).join(", ") + "" : "")}
                                    < br />
                                    {
                                        'Trainingszeitpunkt: ' + (new Date(trainingTime).toLocaleTimeString(navigator.language, {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })) + " Uhr"
                                    }
                                </>)
                            }
                            <br />
                            <br />
                            <PushNotificationConfiguration workoutId={workoutId} onClose={() => init()} variant="button" />
                        </Online>
                    </Paper>
                }
            </DialogContent>
            <DialogActions>
                <Button icon="next" autoFocus onClick={handleOk} color="secondary" variant="contained">
                    Fertig
                </Button>
            </DialogActions>
        </Dialog >
    );
}

CompletionDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        width: '80%',
        maxHeight: 435,
    },
}));
