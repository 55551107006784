import usePushNotifications from './usePushNotifications';

import { TimePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import {
    Typography,
    Fab,
    CardContent,
    Card,
    Divider,
    Box,
    Dialog,
    IconButton,
    ListItemText,
    ListItem,
    List,
    Tooltip,
    Slide,
    DialogContent,
    DialogContentText,
    DialogActions, Step, Paper, StepIcon,
    ListItemIcon, Stepper, StepLabel, StepContent, FormControl, FormLabel, FormGroup, Checkbox, FormControlLabel, FormHelperText
} from '@material-ui/core';
import DayJsUtils from '@date-io/dayjs'; // choose your lib
import { forwardRef } from "preact/compat";
import { pushNotificationService } from '/services/pushNotification';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { HeaderIcon, HeaderIconGroup, Button, DialogTitle, Switch } from '/components/common';
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

import { useEffect, useState } from 'preact/hooks';
import { Offline, Online } from "react-detect-offline";



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));


export default function PushNotificationConfiguration({ workoutId, onClose, variant }) {
    const {
        userConsent,
        pushNotificationSupported,
        onClickAskUserPermission,
        pushServerSubscriptionId,
        userSubscription
    } = usePushNotifications();
    const theme = useTheme();
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);

    const [selectedDate, handleDateChange] = useState(new Date());
    const [state, setState] = useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    });

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            if (!error) {
                pushNotificationService.saveNotificationSchedule(userSubscription, { trainingDays: state, time: selectedDate, workoutId: workoutId }).then((response) => {
                    setDialogOpen(false);
                    onClose();
                }).catch((err) => {
                    setDialogOpen(false);
                    console.log("error " + err)
                });

            }
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = state;
    const error = [monday, tuesday, wednesday, thursday, friday, saturday, sunday].filter((v) => v).length < 1;


    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentUserConsent, setCurrentUserConsent] = useState('');

    function getWeekday(number) {
        const weekdayNames = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
        return weekdayNames[number];
    }

    function getSteps() {
        return [
            'Trainingstage' + ([monday, tuesday, wednesday, thursday, friday, saturday, sunday].filter((v) => v).length > 0 ? ": (" + [monday, tuesday, wednesday, thursday, friday, saturday, sunday].map((value, index) => value ? getWeekday(index) : "").filter((v) => v).join(", ") + ")" : "")
            ,
            'Trainingszeitpunkt: ' + (new Date(selectedDate).toLocaleTimeString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit'
            })) + " Uhr"];
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <>
                    <FormControl required error={error} component="fieldset" className={classes.formControl}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={monday} onChange={handleChange} name="monday" />}
                                label="Montag"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={tuesday} onChange={handleChange} name="tuesday" />}
                                label="Dienstag"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={wednesday} onChange={handleChange} name="wednesday" />}
                                label="Mittwoch"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={thursday} onChange={handleChange} name="thursday" />}
                                label="Donnerstag"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={friday} onChange={handleChange} name="friday" />}
                                label="Freitag"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={saturday} onChange={handleChange} name="saturday" />}
                                label="Samstag"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={sunday} onChange={handleChange} name="sunday" />}
                                label="Sonntag"
                            />

                        </FormGroup>
                        <FormHelperText>{error ? "Bitte wähle mindestens einen Wochentag" : ""}</FormHelperText>
                    </FormControl>
                </>;
            case 1:
                return <>

                    <TimePicker
                        ampm={false}
                        style={{ maxWidth: '310px' }}
                        autoOk
                        variant="static"
                        value={selectedDate}
                        onChange={handleDateChange}
                        minutesStep={5}
                    />
                </>;
            default:
                return 'Unknown step';
        }
    }
    const steps = getSteps();

    const matchesDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const isConsentGranted = userConsent === 'granted';

    const init = async () => {
        if (userSubscription) {
            const notificationSchedule = await pushNotificationService.getNotificationSchedule(userSubscription, workoutId).then((result) => {
                return result;
            }).catch((err) => {
                return [];
            });
            if (notificationSchedule.id) {
                setState({
                    monday: notificationSchedule.send_mondays,
                    tuesday: notificationSchedule.send_tuesdays,
                    wednesday: notificationSchedule.send_wednesdays,
                    thursday: notificationSchedule.send_thursdays,
                    friday: notificationSchedule.send_fridays,
                    saturday: notificationSchedule.send_saturdays,
                    sunday: notificationSchedule.send_sundays,
                });
                handleDateChange(notificationSchedule.sending_time);
            }
        }
    };


    useEffect(() => {
        init();
    }, [userSubscription]);

    useEffect(() => {
        if (userConsent === 'granted' && currentUserConsent !== 'granted') {
            setCurrentUserConsent('granted');
            setDialogOpen(true);
        }
    }, [userConsent]);

    return (
        <>
            <Online            >
                {pushNotificationSupported &&
                    (userSubscription ? (
                        <>
                            {variant === 'icon' ? (
                                <Tooltip title="Erinnerung anpassen">
                                    <IconButton color="primary" aria-label="Erinnerung anpassen" onClick={() => setDialogOpen(true)}>
                                        <NotificationsActiveIcon />
                                    </IconButton>
                                </Tooltip>
                            )
                                : (<Button icon="alert-active" onClick={() => setDialogOpen(true)} color="primary">Erinnerung anpassen</Button>)}
                        </>
                    ) : (
                        isConsentGranted ?
                            (
                                <>
                                    {variant === 'icon' ? (
                                        <Tooltip title="Erinnerung anpassen">
                                            <IconButton color="primary" aria-label="Erinnerung anpassen" onClick={() => setDialogOpen(true)}>
                                                <NotificationsActiveIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                        : (<Button icon="alert-active" onClick={() => setDialogOpen(true)} color="primary">Erinnerung anpassen</Button>)}
                                </>
                            )
                            :
                            (
                                <>
                                    {variant === 'icon' ? (
                                        <Tooltip title="Erinnerung aktvieren">
                                            <IconButton color="primary" aria-label="Erinnerung aktvieren" onClick={onClickAskUserPermission}>
                                                <NotificationsNoneIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                        : (<Button icon="alert-add" onClick={onClickAskUserPermission}>Erinnerung aktvieren</Button>)}
                                </>

                            )
                    )
                    )}
                <br /><br />
                <Dialog
                    open={dialogOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    fullWidth
                    fullScreen={matchesDesktop ? false : true}
                    maxWidth={"sm"}
                    onClose={() => setDialogOpen(false)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">Einstellungen für Erinnerungen</DialogTitle>
                    <DialogContent>
                        <MuiPickersUtilsProvider utils={DayJsUtils}>
                            <DialogContentText id="alert-dialog-slide-description">
                                <Stepper activeStep={activeStep} orientation="vertical" nonLinear>
                                    {steps.map((label, index) => (
                                        <Step key={label}>
                                            <StepLabel color="white" onClick={() => setActiveStep(index)} style={{ cursor: 'pointer' }}><Typography variant="h3" >{label}</Typography></StepLabel>
                                            <StepContent>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    {getStepContent(index)}
                                                </div>
                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                            </DialogContentText>
                        </MuiPickersUtilsProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialogOpen(false)} variant="outlined">Abbrechen</Button>
                        {activeStep !== 0 && <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            variant="outlined"
                            className={classes.button}
                        >
                            Zurück
                        </Button>}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            className={classes.button}
                        >
                            {activeStep === steps.length - 1 ? 'Speichern' : 'Weiter'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Online>
        </>
    );
}
