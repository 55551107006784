import { createState, useState, Downgraded } from '@hookstate/core';
import { configurationService } from '/services';
import { Persistence } from '@hookstate/persistence';


const downloadQueueState = createState({
    workouts: []
})

export function useDownloadQueue() {
    const state = useState(downloadQueueState);
    if (typeof window !== 'undefined') {
        state.attach(Persistence('trainerio-download-queue'));
    }

    return ({
        addToDownloadQueue(item) {
            console.log("adding");
            if (!state.workouts.find(workout => workout.get() ? workout.get().id === item.id : false)) {
                state.set(oldState =>
                ({
                    ...oldState,
                    workouts: [...oldState.workouts, item]
                }))
            }
        },
        updateDownloadQueue(item) {

            console.log("updating");
            console.log(item);
            if (state.workouts.find(workout => workout.get() ? workout.get().id === item.id : false)) {

            console.log("do updating");
                const workouts = state.workouts.attach(Downgraded).get();
                state.set(oldState =>
                ({
                    ...oldState,
                    workouts: [...workouts.filter(workout => item.id !== workout.id), item]
                }))
            }
        }, 
        deleteFromDownloadQueue(workoutId) {
            console.log("deleting");
            const workouts = state.workouts.attach(Downgraded).get();
            state.set(oldState =>
            ({
                ...oldState,
                workouts: workouts.filter(workout => workoutId !== workout.id)
            }));
        },

        getDownloadQueue(workoutId = null) {
            if (workoutId) {
                return state.workouts.find(workout => workout.get() ? workout.get().id === workoutId : false);
            }
            return state.workouts.attach(Downgraded).get();
        },

        get getAllDownloadQueue() {
            return state.workouts.attach(Downgraded).get();
        },
    })
}