import { useEffect, useState, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from '@material-ui/core';


import { HeaderIconGroup, HeaderIcon, Button } from '/components/common';
import { useMediaQuery } from '@material-ui/core';

export const ConfirmationDialog = (props) => {
    const { onClose, value: valueProp, open, ...other } = props;
    const [value, setValue] = useState(valueProp);
    const radioGroupRef = useRef(null); 

    const theme = useTheme();
    const matchesDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);


    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(value);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Dialog
            fullScreen={matchesDesktop ? false : true}
            fullWidth={true}
            maxWidth={"sm"}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">Bitte beachte folgende Hinweise</DialogTitle>
            <DialogContent dividers>
                <Typography>Führe die Übungen nicht durch falls eine der folgenden Punkte auf dich zutreffen:</Typography>
                <Typography>
                    <ul>
                        <li>Liegen behandlungsbedürftige Erkrankungen des Bewegungsapparates vor?<br /></li>
                        <li>Leidest Du an einem Bandscheibenvorfall?<br /></li><li>Hast Du neurologische Ausfälle?<br /></li>
                        <li>Befindest Du Dich in einer postoperativen Phase (ca. 12 Wochen nach Operation)?<br /></li>
                        <li>Leidest Du unter akuten entzündlichen Prozessen, Tumoren oder nicht verheilten Brüchen?<br /></li>
                        <li>Hast Du eine akute Erkrankung mit Fieber?<br /></li>
                        <li>Leidest Du unter akuten Schmerzen, die in Ruhe, unter Bewegung, in der Nacht auftreten?<br /></li>
                        <li>Leidest Du unter akuten Herz-Kreislauf- oder Atemwegserkrankungen?<br /></li>
                        <li>Kennst Du irgendeinen weiteren Grund, warum Du nicht körperlich/ sportlich aktiv sein solltest?</li>
                    </ul>
                </Typography>
                <Typography>
                    Unabhängig der Gesundheitsfragen, achte bitte grundsätzlich bei der Ausführung des Kurses darauf, dass Du eine körperliche Überbeanspruchung und ruckartige Bewegungen bei der Ausführung der Übungen vermeidest.
                    <br />
                    <br />
                    Beende eine Übung bzw das Training vorzeitig, solltest du Schmerzen bei den Übungen verspühren.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button  onClick={handleCancel} color="default" variant="outlined">
                    Cancel
                </Button>
                <Button autoFocus onClick={handleOk} color="secondary" variant="contained">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        width: '80%',
        maxHeight: 435,
    },
}));
