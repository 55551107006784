
import { useEffect } from 'preact/hooks';
import { CssBaseline, useMediaQuery } from '@material-ui/core';
import { createTheme, MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import ErrorBoundary from '/components/common/errorBoundary';
import SocketIo from '/components/common/socketIo';
import '@fontsource/inter';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { Router, Route, Switch } from 'wouter-preact';
import { useUserState } from '/lib/states/user';
import { useClientConfigurationState } from '/lib/states/clientConfiguration';
import { useContentState } from '/lib/states';
import Routes from '/lib/routes';
import { SnackbarProvider } from 'notistack';
import SwWatcher from './swWatcher';
import { Helmet } from "react-helmet";



const useStyles = makeStyles((theme) => {
	return ({
		app: {
			background: theme.palette.background.primary,
			alignSelf: 'center',
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			width: '100%',
			[theme.breakpoints.up('xs')]: {
				maxWidth: '760px',
			},
			position: 'relative',
			marginBottom: '20px',
			padding: 5,
		},
		content: {
			backgroundColor: theme.palette.background.primary,
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
		},
		footer: {
			display: 'block',
		},

		header: {
			position: 'relative',
			minHeight: '65px',
			alignSelf: 'center',
		},

		phoneScreen: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'hidden',
		},

	})
});


const App = () => {
	const classes = useStyles();
	const { user, isLoggedIn, userTheme, verifySession } = useUserState();
	const { getContents } = useContentState();
	const prefersDarkMode = (userTheme === 'auto' && useMediaQuery('(prefers-color-scheme: dark)')) || userTheme === 'dark';
	const { getClientConfiguration, themeDetails, tenantDetails } = useClientConfigurationState();

	let themeLight = createTheme(themeDetails.light);
	let themeDark = createTheme(themeDetails.dark);

	useEffect(() => {
		getClientConfiguration();
		verifySession();
		getContents();
	}, []);

	useEffect(() => {
		themeLight = createTheme(themeDetails.light);
		themeDark = createTheme(themeDetails.dark);
	}, [themeDetails]);



	return (
		<SnackbarProvider maxSnack={3}>
			<Helmet>
				<title>{tenantDetails ? tenantDetails.pageTitle : "test"} - powered by Trainerio</title>
			</Helmet>
			<MuiThemeProvider theme={prefersDarkMode ? themeDark : themeLight}>
				<CssBaseline />
				<ErrorBoundary>
					<div style="position: fixed; bottom: 0; padding: 10px; z-Index: 1000" >
						<img src="/assets/images/cookie_config.png" style="cursor: pointer"
							onClick={() => klaro.show(klaroConfig, true)} />
					</div>
					<I18nextProvider i18n={i18n}>
						<SocketIo />
						<Router>
							<Routes isLoggedIn={isLoggedIn} />
						</Router>
						<SwWatcher />
					</I18nextProvider>
				</ErrorBoundary>
			</MuiThemeProvider>
		</SnackbarProvider>
	)
};

export default App;
