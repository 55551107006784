import { Typography } from "@material-ui/core";
import { useErrorBoundary } from "preact/hooks";
import { Link } from "wouter-preact";

const ErrorBoundary = (props) => {
    const [error, resetError] = useErrorBoundary();
    if (error) {
        return (

            <div style={{ textAlign: 'center', marginTop: '100px' }}>
                <Typography variant="h1">Ooops!</Typography>
                <img src="/assets/images/xx.png" width="200" />

                <Typography variant="body1">Hier ist was schief gelaufen: <br /><br /><b>{process.env.NODE_ENV === 'development' && error.message}</b><br /><br />schnell <Link to="/">nochmal versuchen</Link></Typography>
            </div>
        );
    } else {
        return props.children
    }
};
export default ErrorBoundary;