import { commonService } from './common';

const createExercise = (data) => commonService.request('POST', '/exercises', { data }, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);
const updateExercise = (exerciseId, data) => commonService.request('PUT', `/exercises/${exerciseId}`, { data }, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);
const uploadExerciseMedia = (exerciseId, data) => commonService.request('POST', `/exercises/${exerciseId}/media`, { data }, commonService.HEADER_TYPES.UPLOAD).then((response) => response);
const getExercises = () => commonService.request('GET', '/exercises?filters=[]', {}, commonService.HEADER_TYPES.AUTHORIZED);

const getExerciseMedia = (mediaId,path) => commonService.request('GET', path, {}, commonService.HEADER_TYPES.AUTHORIZED);

const deleteExercise = (exerciseId) => commonService.request('DELETE', `/exercises/${exerciseId}`, {}, commonService.HEADER_TYPES.AUTHORIZED);
const deleteExerciseMedia = (exerciseId, exerciseMediaId) => commonService.request('DELETE', `/exercises/${exerciseId}/media/${exerciseMediaId}`, {}, commonService.HEADER_TYPES.AUTHORIZED);
const getExerciseById = (exerciseId) => commonService.request('GET', `/exercises/${exerciseId}`, {}, commonService.HEADER_TYPES.AUTHORIZED);

export const exerciseService = {
    uploadExerciseMedia,
    createExercise,
    updateExercise,
    deleteExercise,
    getExerciseById,
    getExercises,
    deleteExerciseMedia,
    getExerciseMedia,
};
