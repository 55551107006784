import { commonService } from './common';

const sendPushNotification = (pushServerSubscriptionId) => commonService.request('GET', `/push/subscription/${pushServerSubscriptionId}`, {}, commonService.HEADER_TYPES.AUTHORIZED);

const subscribeToPushNotification = (subscription) => commonService.request('POST', '/push/subscription', {
    data: { subscription },
}, commonService.HEADER_TYPES.AUTHORIZED);

const getNotificationSchedule = (subscription, workoutId) => commonService.request('POST', '/push/schedule/' + workoutId, {
    data: { subscription, workoutId },
}, commonService.HEADER_TYPES.AUTHORIZED);

const saveNotificationSchedule = (subscription, details) => commonService.request('POST', '/push/schedule', {
    data: { subscription, details },
}, commonService.HEADER_TYPES.AUTHORIZED);

export const pushNotificationService = {
    subscribeToPushNotification,
    sendPushNotification,
    getNotificationSchedule,
    saveNotificationSchedule
};
