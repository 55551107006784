import { useEffect, useState } from 'preact/hooks';
import { Paper, useMediaQuery, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Header from '/components/template/header';
import Help from '/components/common/help';
import { Link, useLocation } from 'wouter-preact';

import DownloadQueue from '/components/common/downloadQueue';
const useStyles = makeStyles((theme) => {

    return ({
        app: {
            background: theme.palette.background.primary,
            alignSelf: 'center',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            [theme.breakpoints.up('xs')]: {
                maxWidth: '640px'
            },
            [theme.breakpoints.up('sm')]: {
                marginBottom: '110px'
            },
            position: 'relative',
            overflowY: 'auto',
            zIndex: 0,
        },
        appLandscape: {
            background: theme.palette.background.primary,
            alignSelf: 'center',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            [theme.breakpoints.up('xs')]: {
                maxWidth: '640px',
                '@media (max-height: 765px) and (orientation: landscape)': {
                    maxWidth: '950px'
                  },
            },
            [theme.breakpoints.up('sm')]: {
                marginBottom: '110px'
            },
            position: 'relative',
            overflowY: 'auto',
            zIndex: 0,
        },
        content: {
            backgroundColor: theme.palette.background.primary,
            display: 'flex',
            
            flex: 2,
            zIndex:-1,
            flexDirection: 'column',
            [theme.breakpoints.up('xs')]: {
                '@media (max-height: 765px) and (orientation: landscape)': {
                    flexDirection: 'row',
                  },
            },
        },
        helpSection: {

            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            height: '60px',
        },
        footerMobile: {
            textAlign: 'center',
            position: 'fixed',
            bottom: '0',
            width: '100%',
            maxWidth: 640
        },
        header: {
            position: 'relative',
            zIndex:0, 
            alignSelf: 'center',
        },

        phoneScreen: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'hidden',

        },

    })
});

const Main = ({ children, headerDirection, headerColor, ...props }) => {
    const classes = useStyles();

    const [location, setLocation] = useLocation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesDesktop = useMediaQuery(theme.breakpoints.down('sm'));
    const [hideElements, setHideElements] = useState(false);
    const [appStyle, setAppStype] =useState("app");
    useEffect(() => {
        if (location.indexOf("/uebung/") !== -1) {
            setHideElements(true);
        
            setAppStype("appLandscape");
        } else {
            setHideElements(false);
            setAppStype("app");
        }
    }, [location]);
    return (
        <div className={classes.phoneScreen}>
            <Paper className={classes[appStyle]} style={{ marginBottom: hideElements && 'unset'}} elevation={6} square>
                <div className={classes.header} style={{ minHeight: hideElements ? 0 : '100px' }}>
                    {!hideElements && <Header direction={headerDirection} color={headerColor} />}
                </div>
                <section className={classes.content}>
                    {children}
                </section>
                { !hideElements && 
                ( <>
                    {matches && <div style={{ background: theme.palette.gradient.primary, textAlign: 'center', marginBottom: '100px' }}>
                    <Typography variant="overline" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} href="/seiten/nutzungsbedingungen">Nutzungsbedingungen</Link>
                        <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} href="/seiten/datenschutz">Datenschutz</Link>
                        <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} href="/seiten/agb">AGB</Link>
                        <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} href="/seiten/impressum">Impressum</Link>
                    </Typography>
                    <a style={{ color: '#FFFFFF', textDecoration: 'none', textAlign: 'center' }} rel="noreferrer" href="https://www.trainerio.com" target="_blank">© {(new Date()).getFullYear()} Trainerio</a>
                </div>}
                
                <Paper elevation={6} className={classes.footerMobile} square>
                    <div className={classes.helpSection}>
                        <div><Help /></div>
                    </div>
                    {!matches && <div style={{ background: theme.palette.gradient.primary, textAlign: 'center' }}>
                        <Typography variant="overline" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} href="/seiten/nutzungsbedingungen">Teilnahmebedingungen</Link>
                            <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} href="/seiten/datenschutz">Datenschutz</Link>
                            <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} href="/seiten/agb">AGB</Link>
                            <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} href="/seiten/impressum">Impressum</Link>
                        </Typography>
                        <a style={{ color: '#FFFFFF', textDecoration: 'none', textAlign: 'center' }} rel="noreferrer" href="https://www.trainerio.com" target="_blank">© {(new Date()).getFullYear()} Trainerio</a>
                    </div>}
                </Paper></> )}
                <DownloadQueue />
            </Paper>
        </div>
    )
};

export default Main;
