
import Dexie from 'dexie';

const database = new Dexie('trainerio-db');

database.version(1).stores({
    media: `[workoutId+path],workoutId`,
    workout: `workoutId`,
});

export const db = database;