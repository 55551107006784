//import history from '/lib/helpers/history';
import { commonService } from './common';

const logout = (clientOnly = false) => {

    if (!clientOnly) {
        commonService.request('GET', '/auth/logout', commonService.HEADER_TYPES.AUTHORIZED).then(() => { });
        //history.go('/');
    }
    return {};
};

const login = (username, password, rememberMe) => commonService.request('POST', '/auth/login', {
    data: {
        username, password, remember_me: rememberMe,
    },
});

const verifyOtp = (username, password, rememberMe, otp) => commonService.request('POST', '/auth/verify-otp', {
    data: {
        username, password, remember_me: rememberMe, otp,
    },
});

const verifySession = () => commonService.request('GET', '/users/verify').then((response) => {
    if (response.logged_in === false) {
        logout(true);
    }
    return response;
}).catch((error) => {
    if (error.statusCode === 403 || error.statusCode === 401) {
        logout(true);
    }
});

const register = (data) => commonService.request('POST', '/onboarding', { data }, commonService.HEADER_TYPES.AUTHORIZED);



const getUserById = (userId) => commonService.request('GET', `/users/${userId}`, {}, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);

const updateUser = (userId, data) => commonService.request('PUT', `/users/${userId}`, { data }, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);

const uploadAvatar = (userId, data) => commonService.request('PUT', `/users/${userId}/avatar`, { data }, commonService.HEADER_TYPES.UPLOAD).then((response) => response);

const forgotPassword = (username) => commonService.request('POST', '/users/forgot-password', {
    data: {
        username,
    },
});

const changePassword = () => commonService.request('GET', '/users/change-password', {}, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);


const validatePasswordLink = (data) => commonService.request('POST', '/auth/validate-reset-link', { data }, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);
const validateDeletionLink = (data) => commonService.request('POST', '/auth/validate-deletion-link', { data }, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);
const deleteAccount = (data) => commonService.request('DELETE', '/users/delete-account', { data }, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);

const getPreferences = () => commonService.request('GET', '/users/profile', { raw: true }, commonService.HEADER_TYPES.AUTHORIZED).then((response) => {
    if (response.headers['csrf-token']) {
        localStorage.setItem('currentToken', response.headers['csrf-token']);
    }
    return response.data;
});

const updatePreferences = (userId, data) => commonService.request('PUT', `/users/${userId}/preferences`, { data }, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);

const resetPassword = (data) => commonService.request('POST', '/auth/reset-password', { data }, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);

export const userService = {
    login,
    logout,
    verifyOtp,
    register,
    verifySession,
    getPreferences,
    updateUser,
    uploadAvatar,
    getUserById,
    validatePasswordLink,
    validateDeletionLink,
    resetPassword,
    forgotPassword,
    changePassword,
    deleteAccount,
    updatePreferences
};
