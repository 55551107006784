import { Route, Switch, Redirect } from 'wouter-preact';
import Page from '/routes/page';
import Main from '/components/template/main';
import NotFound from '/routes/notFound';
import WorkoutOverview from '/routes/workoutOverview';
import WorkoutCategoryOverview from '/routes/workoutCategoryOverview';
import Workout from '/routes/workout';
import WorkoutItem from '/routes/workoutItem';

const Routes = ({ isLoggedIn }) => {
    return (
        <Switch>
            <Route path={"/"} >{params => <Main headerDirection='right'><WorkoutCategoryOverview /></Main>}</Route>
            <Route path={"/kategorien"} >{params => <Main headerDirection='right'><WorkoutCategoryOverview /></Main>}</Route>
            <Route path={"/kategorien/:workoutCategory"} >{params => <Main headerDirection='right'><WorkoutCategoryOverview workoutCategory={params.workoutCategory}  /></Main>}</Route>
            <Route path={"/uebungen/:workoutId"}>{params => <Main headerDirection='right'><Workout workoutId={params.workoutId} /></Main>}</Route>
            <Route path={"/uebungen/:workoutId/uebung/:workoutItemId"}>{params => <Main headerDirection='right'><WorkoutItem workoutId={params.workoutId} hideHeader={true} workoutItemId={params.workoutItemId} /></Main>}</Route>
            <Route path={"/workouts/:workoutId"}>{params => <Main headerDirection='right'><Workout workoutId={params.workoutId} /></Main>}</Route>
            <Route path={"/workouts/:workoutId/items/:workoutItemId"}>{params => <Main headerDirection='right'><WorkoutItem workoutId={params.workoutId} hideHeader={true} workoutItemId={params.workoutItemId} /></Main>}</Route>
            <Route path={"/seiten/:contentId"}>{params => <Main headerDirection='right'><Page contentId={params.contentId} /></Main>}</Route>
            <Route path={"/pages/:contentId"}>{params => <Main headerDirection='right'><Page contentId={params.contentId} /></Main>}</Route>
            <Route path={"/seite-nicht-gefunden"} component={NotFound}></Route>
            <Route component={NotFound}></Route>
        </Switch >
    );
};


export default Routes;
