
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    AppBar, Toolbar, Typography,useMediaQuery
} from '@material-ui/core';
import { useUserState, useClientConfigurationState } from '/lib/states';
import { HeaderIcon } from '/components/common';
import { TheatersOutlined } from '@material-ui/icons';
import { Link, useLocation } from "wouter-preact";

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        height: '40px',
        background: theme.palette.background.primary,
        position: 'fixed',
        left: '50%',
        top: '0%',
        transform: 'translateX(-50%)',
        maxWidth: '640px',
        width: '100%',
        [theme.breakpoints.up('xs')]: {
            maxWidth: '640px',
        },
    },
    sectionDesktop: {
        position: 'absolute',
        right: '0',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    svg: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        marginBottom: -1,
        ['@media (min-width:760px)']: { // eslint-disable-line no-useless-computed-key
            height: "20px !important",
        },
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            height: '5vw',
        },
    },
}));

export default function Header({ direction }) {
    const classes = useStyles();
    const theme = useTheme();
    const [location, setLocation] = useLocation();
    const { isLoggedIn } = useUserState();
    const {  tenantDetails } = useClientConfigurationState();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));



    return (
        <>
            <AppBar id="trainerio-header" position="fixed" className={classes.appBar} style={{ height: '40px' }} elevation={0} align="center">
            <Toolbar
                    style={{
                        maxHeight: '25px',minHeight: '25px', position: 'relative', display: 'flex',flexDirection: 'row',justifyContent: 'space-between',alignItems: 'center', background: theme.palette.gradient.primary
                    }}
                >
              <div style={{color:'#FFFFFF'}}>
                  &lt;&nbsp;<a href={tenantDetails.homepageDeepLink} style={{color:'#FFFFFF', fontSize: (matches ? '0.7rem' : '0.875rem')}}> zurück zu {tenantDetails.name}</a>
              </div>
              <div style={{color:'#FFFFFF'}}>
              <Link to="/" style={{color:'#FFFFFF', fontSize: (matches ? '0.7rem' : '0.875rem')}}>Übersicht der Übungen</Link>
              </div>
              </Toolbar>
                <Toolbar
                    style={{
                        minHeight: matches ? '60px': '80px', maxHeight: matches ? '60px': '80px', position: 'relative', display: 'flex',flexDirection: 'row',justifyContent: 'space-between',alignItems: 'center',background: theme.palette.background.primary
                    }}
                >
              <Link to="/"><img alt={tenantDetails.pageTitle} src={ "/media/" + tenantDetails.logo + "/" + tenantDetails.logo + ".png"} style={{height:matches ? '30px': '60px', cursor: 'pointer'}} /></Link>
              <div style={{
                        display: 'flex',flexDirection: 'row',justifyContent: 'space-between',alignItems: 'center',
                    }}>
                  <Typography variant="subtitle1" style={{color: 'black'}}>powered by</Typography>
                  <img src="/assets/images/trainerio-logo.svg" alt="Trainerio" style={{paddingLeft: '20px', height: matches ? '60px': '80px'}} />
              </div>
              </Toolbar>
                {(['/mitgliederbereich', '/mitgliederbereich/', '/mitgliederbereich/kurse'].includes(location) && isLoggedIn)
                    && (
                        <Toolbar disableGutters className={classes.sectionDesktop}>
                            <HeaderIcon variant="user" onClick={() => setLocation("/mitgliederbereich/einstellungen")} />
                        </Toolbar>
                    )}
            </AppBar>
        </>
    );
}
