import { commonService } from './common';

const createWorkout = (data) => commonService.request('POST', '/workouts', { data }, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);
const updateWorkout = (workoutId, data) => commonService.request('PUT', `/workouts/${workoutId}`, { data }, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);

// const getWorkoutsByCode = (shortCode) => commonService.request('GET', `/med-workouts/${shortCode}`, {}, commonService.HEADER_TYPES.AUTHORIZED);
const getWorkoutsByCode = (shortCode) => commonService.request('GET', '/workouts', {}, commonService.HEADER_TYPES.AUTHORIZED);
const getWorkouts = (filter = {}) => commonService.request('GET', '/workouts?filters=' + JSON.stringify(filter), {}, commonService.HEADER_TYPES.AUTHORIZED);
const getWorkoutById = (workoutId) => commonService.request('GET', `/workouts/${workoutId}`, {}, commonService.HEADER_TYPES.AUTHORIZED);
const getMedWorkoutById = (workoutId) => commonService.request('GET', `/med-workouts/${workoutId}`, {}, commonService.HEADER_TYPES.AUTHORIZED);

const deleteWorkout = (workoutId) => commonService.request('DELETE', `/workouts/${workoutId}`, {}, commonService.HEADER_TYPES.AUTHORIZED);

export const workoutService = {
    getWorkoutsByCode,
    getWorkouts,
    createWorkout,
    updateWorkout,
    getMedWorkoutById,
    getWorkoutById,
    deleteWorkout,
};
